/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  min-width: 130px;
} */
/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: unset;
} */
/* .css-aphx2v-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: var(--radius);
} */

.mapboxgl-ctrl-geocoder {
  background-color: rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid rgb(135, 135, 135);
}
@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
      border-radius: 25px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
  }
}
.mapboxgl-ctrl-geocoder--input {
  border-width: 1px;
  width: -webkit-fill-available;
  display: inline-block;
  padding: 15px;
  padding-left: 35px;
  font-size: 18px;
  border-radius: 25px;
  color: white;
  border: none;
  outline: none;
}
.mapboxgl-ctrl-geocoder--input:focus{
  color: white;
}
.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 0px;
}
.mapboxgl-ctrl-geocoder--button{
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  right: 4px;
  top: 3px;
}
.MuiPickersCalendarHeader-label{
  color: var(--white); 
}
