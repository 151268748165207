
:root {
  --color-primary: #ff6f00;
  --color-primary50: #ff6d0080;
  --color-primary75: #ff6f00bb;
  --color-primar-dark: #8b4109;
  --color-success: #51fcba;
  --color-success-dark: #169967;
  --color-danger: #ff4444;
  --bg: #f1f1f1;

  --white: #1e1e1e;
  --black: #fff;
  --gray: #999999;
  --gray-light: #b4b4b4;
  --gray-dark: #4a4a4a;
  --gray-darker: #1d1b1b;
  --green: #4caf50;

  --input-back: #2b2b2b;
  --input-error-back: #ff444480;
  --radius: 10px;
  --text: var(--white); 
  --content-height: 100vh; 
  /* //calc(100vh - 60px); */
  --box-shadow-color: rgba(0, 0, 0, 0.1);
  --border-shadow: 0 4px 6px -1px var(--box-shadow-color), 0 2px 4px -1px var(--box-shadow-color);

  --options-card: #d5d5d587;

}

.dark-mode {
  --color-primary: #ff6d00;
  --color-success: #51fcba;
  --color-success-dark: #169967;
  --color-danger: red;
  --bg: #0e0e0e;
  --white: #fff;
  --black: #000;
  --gray: #707070;
  --gray-light: #adadad;
  --gray-dark: #525252;
  --gray-darker: #282828;
  --green: #51fcba;

  --input-back: #ffffff26;
  --input-error-back: #ff00004d;
  --radius: 10px;
  --text: var(--white);
  --box-shadow-color: rgba(255, 255, 255, 0.1);
  --border-shadow: 0 2px 4px 2px var(--box-shadow-color), 0 4px 7px -4px var(--box-shadow-color);

  --options-card: #202020c4;

}

body{
  background-color: var(--bg);
  background-size: cover;
  overflow-x: hidden;
}

.container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.content-container {
  position: relative; 
  height: var(--content-height);
  /* top: 60px; */
  width: 100vw;
  display: grid;
  grid-template-columns: 220px 1fr;
}
.content-container.clean{
  grid-template-columns: 1fr;
}
.content-container.clean .onboard-side-menu, .mobile-footer.clean  {
  display: none;
}
@media screen and (max-width: 640px) {
  .content-container {
    grid-template-columns: auto;
  }
}
/* .content-container div:nth-child(1) {
  overflow: hidden;
} */

.content-container .main-content-container {
  /* overflow-y: scroll;  */
  overflow-x: hidden; 
  /* padding: 0 10px; */
  /* display: flex ; */
}
.content-container .main-content-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Make sure it sits above the page content */
}

chat-wiget button {
  bottom: 58px;
}
@media (max-width: 640px) {
  .content-container {
    top: 0px;
    min-height: var(--content-height);
  }
}
.desktopMaxWidth {
  /* Default styles */
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
@media (max-width: 640px) {
  .desktopMaxWidth {
    width: 95%;
  }
}
.auth-contain-two-col {
  max-width: 440px;
  width: 100%;
  margin: auto;
}
@media (max-width: 940px) {
  .auth-contain-two-col {
    width: 90%;
  }
}
.guest-two-col{
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: 100vw; */
  min-height: 100%;
  align-items: center;
  overflow: hidden;
  margin: unset;
}
.two-col{
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* //column-gap: 20px; */
  align-items: center;
  min-height: var(--content-height);
  width: 100%;
}
.guest-two-col .side-display, .two-col .side-display{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 60px);
  /* height: 100%; */
  width: 100%;
}
.side-display img{
  height: 100vh;
}
@media (max-width: 640px) {
  .guest-two-col {
    grid-template-columns: 1fr;
  }
  .guest-two-col .side-display{
    display: none;
  }
}
@media (max-width: 1080px){
  .two-col{
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
  }
  .two-col .side-display{
    display: none;
  }
}
.flex {
  display: flex;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
.spaceBtw {
  justify-content: space-between;
}
.one-col{
  display: grid;
  grid-template-columns: auto;
  column-gap: 20px;
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 20px 0 10px 0;
  background-color: var(--black);
  z-index: 10;
  display: flex;
  justify-content: center;
}
.exit{
  background-color: var(--black);
  border: unset;
  color: var(--gray-dark);
}
.box-shadow{
  box-shadow: var(--border-shadow); 
}
/* Desktop Menu Styles */
.desk-menu {
  position: fixed;
  width: 100vw;
  top: 0;
  height: 60px;
  background-color: var(--black);
  box-shadow: var(--border-shadow);
  z-index: 1;
}
.desk-menu .desk-menu-contain{
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 11fr;
  align-items: center;
  justify-content: space-between;
}

.desk-menu .menuItem {
  color: var(--white);
  margin: 0 10px;
}

.desk-menu .menuItem span {
  text-decoration: none;
  padding: 0px 5px;
}

.desk-menu .menuItem span:hover {
  color: var(--color-primary);
}

.desk-menu .menuItem.active {
  font-weight: 900;
  padding-bottom: 2px;
  border-bottom: var(--color-primary);
  border-bottom-style: solid;
  border-bottom-width: 3px;
}
.desk-menu-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.reg-btn{
  background-color: var(--color-primary) !important; 
  border-radius: var(--radius) !important; 
  width: 90%  !important; 
  margin: auto  !important; 
  color: white  !important; 
  font-weight: 800  !important; 
  display: flex !important;
  justify-content: center !important;
}
/* Hamburger Menu Styles */
.hamburger-menu {
  position: fixed;
  top: 20px;
  color: white;
  background-color: var(--gray-dark);
  padding: 12px;
  border-radius: 50%;
  right: 20px;
  height: 20px;
  width: 20px;
  z-index: 1000;
  cursor: pointer;
  font-size: 20px;
  display: none; /* Hidden on larger screens */
}
.close-menu-btn {
  top: 20px;
  color: white;
  background-color: var(--gray-dark);
  padding: 12px;
  border-radius: 50%;
  right: 20px;
  height: 20px;
  width: 20px;
  z-index: 1000;
  cursor: pointer;
  font-size: 20px;
}
.side-menu {
  position: fixed;
  top: 0;
  right: -300px; /* Hidden by default */
  width: 250px;
  height: 100%;
  background-color: var(--gray-darker);
  z-index: 1001;
  transition: right 0.3s ease-in-out;
  padding: 20px;
}

.side-menu.open {
  right: 0; /* Show menu when open */
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-items {
  margin-top: 20px;
  text-align: left;
  color: white;
  font-size: 16px;
}
.menuItem {
  margin-bottom: 20px;
}
/* Responsive Design: Show Hamburger Menu on Smaller Screens */
@media screen and (max-width: 640px) {
  .desk-menu {
    display: none; /* Hide the desktop menu on small screens */
  }

  .hamburger-menu {
    display: block; /* Show hamburger on small screens */
  }
}

/* Show Desktop Menu on Larger Screens */
@media screen and (min-width: 641px) {
  .hamburger-menu {
    display: none; /* Hide hamburger on large screens */
  }

  .desk-menu {
    display: grid; /* Show the desktop menu */
  }
}

.back-black {
  background-color: var(--black);
}
.footer {
  position: relative;
  /* min-height: 115px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* bottom: 0;
  left: 0; */
  background-image: linear-gradient(
    131deg,
    var(--black) 77%,
    var(--gray-darker) 57%
  );
  width: 100%;
  border-top: solid;
  border-top-color: var(--gray);
}
.footer-inner {
  width: 100vw;
  max-width: 640px;
  padding: 10px 10px;
  margin-left: auto;
  margin-right: auto;
}
.footer-highlight {
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: smaller;
}
.footer .price {
  align-items: baseline;
}

.footer p{
  color: var(--gray-dark);
}
.footer-profile{
  margin-top: 20px;
  margin-bottom: 100px;
}
.yellow{
  color: var(--color-primary); 
}
.yellow_dark{
  color: var(--gray-dark);
}
.white{
  color: var(--white); 
}
.light_gray{
  color: var(--gray-light);
}
.gray{
  color: var(--gray);
}
.orange{
  color: var(--color-primary);
}
.green{
  color: var(--green);
}
.centerView {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerVert {
  justify-content: center;
}
.leftView {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.input {
  background-color: #fff;
  border-width: 1px;
  width: -webkit-fill-available;
  display: inline-block;
  padding: 15px;
  padding-left: 20px;
  font-size: 18px;
  border-radius: 25px;
  color: white;
  border: unset;
}
/* Ensure the input text color is white */
.input .MuiInputBase-input {
  color: white;
}

/* Ensure the label color is white */
.input .MuiInputLabel-root {
  color: white;
}
input:focus {
  outline: none;
}
.input {
  min-height: 41px;
  background-color: #ffffff26;
  border-width: 1px;
  display: inline-block;
  font-size: 18px;
  border-radius: 25px;
  color: white;
  border: unset;
}
.input-sheet{
  display: grid;
  grid-template-rows: auto;
  grid-gap: 40px;
  text-align: left;
}
.errorInput {
  background-color: #ff00004d;
  border-width: 0;
}

.errorText {
  color: var(--color-danger);
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.allCaps {
  text-transform: uppercase;
}
h1, h2,h3,h4,h5{
  margin-bottom: unset;
}
h1 {
  color: var(--white);
  font-size: 30px;
  font-weight: 600;
  font-family: sans-serif;
}

h2 {
  color: var(--white);
  font-size: 30px;
  font-weight: 600;
}

h3 {
  color: var(--white);
  font-size: 25px;
  font-weight: 500;
}

p {
  color: var(--white);
  margin-top: 10px;
}

.label {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 300;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.alscenter {
  align-self: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dash-icon-box label {
  color: white;
  padding-top: 10px;
}
.flexSpace {
  justify-content: space-between;
}

.flexAround {
  justify-content: space-around;
  align-items: center;
}

.flexRow {
  flex-direction: row;
}

.flexCol {
  flex-direction: column;
}

.flexEnd {
  align-self: flex-end;
}

.flexWrap {
  flex-wrap: wrap;
}

.alignCenter {
  align-items: center;
}

.noLogHeader {
  justify-content: flex-end;
  height: 25%;
}
footer .logo {
  margin-bottom: 5px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
footer .logo img {
  height: 30px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
ul.footer-links {
  list-style: none;
  padding-left: 0; /* Removes any default padding */
}

ul.footer-links li {
  list-style-type: none; /* Ensures no markers (bullets or numbers) */
  margin: 0; /* Optional: Removes any margin that might cause spacing issues */
  padding-left: 0; /* Optional: Ensures no left padding on list items */
}
.footer-links {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 640px){
  .footer-links {
    flex-direction: column;
  }
}
footer a {
  color: var(--white);
  margin: 0 10px;
  text-decoration: none;
  font-weight: bolder;
}
.welcomeLogo {
  max-width: 145px;
  margin-bottom: 20px;
  display: flex;
  margin-right: auto;
}

.noLogLogo {
  max-width: 145px;
  margin-bottom: 5px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.noLogFavicon {
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.noLogFavicon {
  width: 30px;
  height:30px;
  margin-bottom: 5px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.noLogContent {
  justify-content: flex-start;
  align-items: center;
  height: 60%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: white;
  font-size: 18px;
}

.tabBar {
  display: flex;
  position: absolute;
  width: 60%;
  right: 17.5%;
  left: 22.5%;
  bottom: 30px;
  border-radius: 20px;
  height: 50px;
  background-color: #fff;
  border-top-width: 0;
}

.tabIconContainer {
  align-items: center;
  justify-content: center;
  top: 15px;
}

.tabIcon {
  width: 25px;
  height: 25px;
}

.myLocalIcon {
  width: 50px;
  height: 50px;
}

.tabHighlightIcon {
  width: 75px;
  height: 75px;
  position: absolute;
  top: -15px;
  left: -15px;
}

.shadow {
  box-shadow: 0px 0px 54px 15px #73737394;
  /* background: linear-gradient(to bottom right, #ff7e5f, #feb47b); */
}

.bbo {
  border-bottom-color: var(--color-primary);
  border-bottom-width: 2px;
  margin-bottom: 30px;
  height: 30px;
}

.bbg {
  border-bottom-color: #707070;
  border-width: 1px;
}

.bb0 {
  border-bottom-color: unset;
}

.br10 {
  border-radius: 10px;
}

.cust_header {
  justify-content: flex-end;
  text-align: left;
  color: var(--gray-light);
  margin-bottom: unset;
  margin-top: unset;
  border-bottom: 1px solid var(--gray-light);
  font-size: 20px;
  font-weight: 600;
}

.badgesContainer {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.badge {
  background-color: #32900f80;
  border-color: #32900f;
  border-width: 1px;
}

.dropdown_btn {
  background-color: #ffffff26;
  border-radius: 10px;
  width: 100%;
}

.dropdown_text {
  color: #fff;
}

.dropdown {
  background-color: #ffffff33;
}
.toggle-tbn {
  border-top-left-radius: 20;
}

.obtn {
  background-color: var(--color-primary);
  width: 70%;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-left: 10px;
}

.gbtn {
  justify-content: center;
  align-items: center;
  background-color: gray;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 20px;
  padding-left: 20%;
}

.btn-rate{
  display: grid;
  border: 1px solid var(--gray-light);
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-top-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.fw400 {
  font-weight: 400;
}

.grbdr {
  border-color: #707070;
  border-width: 2px;
  border-radius: 50px;
  padding: 10px;
  width: 35%;
  text-align: center;
  align-items: center;
}

.marker {
  width: 24px;
  height: 24px;
  background-color: #ff0000; /* Adjust the color as needed */
  border-radius: 50%;
  cursor: pointer;
}
.mapbox-modal-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.75;
}
.mapbox-modal-text {
  position: absolute;
  top: 0;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.75em;
  font-weight: 700;
}
.mapboxgl-popup-content {
  padding: unset;
  background: #000;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #000;
}
.mapboxgl-popup-close-button {
  color: lightgray;
}
.radius-input{
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.radius-input h3{
 margin: unset;
 padding-right: 20px;
}
.searchImg {
  width: 100%;
}

.horScroll {
  overflow-y: scroll;
  scrollbar-width: none;
}


ul.scrollableList {
  padding: unset;
  margin: unset;
  width: 100%;
}
ul.scrollableList li:nth-child(1){
  padding-top: unset;
}
ul.scrollableList li{
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
ul.scrollableList li img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  border-radius: var(--radius);
}
ul.scrollableList li p{
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0px 0;
  margin: 0px 0 0;
  text-align: left;
  font-size: 20px;
}
ul.scrollableList li p:nth-child(2){
  font-size: 16px;
  color: var(--gray);
}
ul.scrollableList li .sepRow p{
  display: flex;
  align-items: center;
  padding-top: 5px;
  font-size: 16px;
}
@media (max-width: 640px) {
  ul.scrollableList li{
    display: grid;
    grid-template-columns: 3fr 4fr;
  }
  ul.scrollableList li p, ul.scrollableList li div div{
    padding: 0px 0 5px 10px;
  }
  ul.scrollableList li:nth-child(1){
    padding-top: 10px;
  }
}
.scrollableList .sepRow{
display: flex;
justify-content: space-between;
}
.scrollableList .sepRow p:nth-child(2){
  text-align: right;
  justify-content: flex-end;
}
.toggle-container{
  /* background: black; */
  height: 60px;
  width: 100%;
  max-width: 1176px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
  justify-content: center;
  margin: 10px auto;
  box-sizing: border-box; 

}
.toggle-container .toggle-btn{
  background-color: var(--color-primary50);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  color: white; 
  padding: 10px 0;
}
@media (max-width: 640px) {
  .mobile-search-btn {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    background: black;
    height: 10vh;
    align-items: center;
  }

  .search-container {
    display: flex;
    flex-direction: column;
  }
  .search-list-container {
    position: relative;
    z-index: 2;
    top: calc( 35vh + 80px );
    overflow: auto;
    /* height: var(--content-height); */
    height: calc(var(--content-height) - 60px);
    width: 100vw;
    transition: top 0.75s ease;
    margin-bottom: 50vh;
  }

  .search-list-container.clicked {
    top: 0vh;
    transition: top 0.75s ease;
  }
  ul.scrollableList {
    overflow-y: auto;
    height: calc(100vh - 100px);
    margin: 0;
    list-style: none;
    padding: unset;
  }
  li.scrollable-list-space{
    height: 30vh;

  }
  .map-container {
    position: fixed;
    order: -1;
    overflow: auto;
    height: 43vh;
    width: 100%;
    /* height: 35vh; */
    /* top: 80px; */
  }
  .horScroll {
    flex-direction: column;
  }
}
@media (min-width: 641px) {
  .mobile-search-btn {
    display: none;
    transform: translateY(100px);
    transition: all 0.5s ease;
  }

  .map-container {
    overflow: auto;
    /* height: calc(var(--content-height) - 80px); */
    height: calc(var(--content-height));
  }
  .horScroll {
    flex-direction: column;
    overflow: hidden;
  }
  .gym-hori-img {
    margin-right: 10px;
  }
}
@media (max-width: 640px){
  .toggle-container {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 10px auto;
    padding: 10px; /* Ensure padding doesn't cause overflow */
    box-sizing: border-box; 
    z-index: 1;
  }
  .search-container {
    position: fixed;
  }
}
@media (min-width: 641px){
  .search-container {
    /* display: flex; 
    flex-direction: row; */
    display: grid;
    grid-template-columns: 1.5fr 3fr;
  }
  .search-list-container {
    overflow: auto;
    height: calc(var(--content-height));
    /* height: calc(var(--content-height) - 80px); */
  }
}

@media (min-width: 1080px) {
  .search-container{
    grid-template-columns: 1fr 3fr;
  }
  .search-list-container {
    overflow: auto;
    /* width: 35vw; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: var(--content-height);
    /* height: calc(var(--content-height) - 80px); */
  }
  .map-container {
    overflow: auto;
    height: var(--content-height);
    /* height: calc(var(--content-height) - 80px); */
    width: 100%;
    margin: 0 auto 0 0;
  }
  .horScroll {
    flex-direction: column;
  }
}

.fullPageSlider {
  position: fixed;
  top: 0px;
  right: -100vw;
  width: 100vw;
  min-height: 100%;
  background-color: var(--bg); 
  transition: right 0.3s ease-in-out 0s;
  z-index: 999;
}

.prev-gallery {
  aspect-ratio: 1 / 1;
  width: 22%;
  border-radius: 20px;
  object-fit: cover;
  padding: 3px;
}

.section-label {
}
.cls-1 {
  fill: #0040ff !important;
}

.chp-border {
  align-items: center;
  border-width: 2px;
  /* border-style: solid;
  border-color: gray; */
  border-radius: 2em;
  padding: 12px;
}

.hidden {
  display: none;
}

.summary-box {
  padding: 5px 0 20px 0;
}
.strike-thru {
  text-decoration: line-through;
  color: red !important;
}
.summary-box span {
  color: var(--white);
  font-weight: 500;
  font-size: larger;
}
.summary-box .strike-thru {
  text-decoration: line-through;
  color: red;
}
.ani-checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--color-base);
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--color-theme);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.security {
  position: relative;
  width: 100%;
  max-width: 25%;
}
.policy {
  text-align: justify;
}
.success {
  font-size: 35px;
}
.view-toggle {
  background-color: #2e2e2e;
  padding: 10px 15px;
  border-radius: 2em;
}

.dash-contain {
  max-width: 640px;
  margin: 0px auto;
}

.dash-box {
  margin: 10px auto;
  padding: 20px 0px;
  /* background: #0000009e; */
  /* background-color: white; */
  max-width: 640px;
  border-radius: 15px;
  overflow: hidden;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);  */
}

.dash-wallet {
  background-color: var(--green);
  border-radius: 15px;
  padding: 20px 0;
}

.dash-wallet label {
  color: var(--gray);
}
.dash-wallet p {
  color: var(--gray-darker);
  margin: unset;
  font-size: 60px;
}

.upload-box {
  height: 35vh;
  background: var(--gray-darker);
  color: var(--gray-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--gray);
  border-style: dotted;
  border-width: 5px;
  border-radius: 15px;
}
.upload-box input[type="file"] {
  display: none;
}
.upload-box-label {
  padding: 20px;
}
.upload-confirm {
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  right: 10px;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  padding-left: 50%;
  /* height: 100vh; */
}

.loader {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}

.path {
  stroke: var(--color-primary);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.textButton {
  cursor: pointer;
}

.hidden-scroll {
  /* Set overflow to auto to enable scrolling */
  overflow: auto;
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.dash-icon-box {
  cursor: pointer;
  /* background-color: #0001; */
  /* border-radius: 50%; */
  padding: 8px;
}

.dash-icon-box:hover {
  background-color: #0003;
}
.dashMenu {
  gap: 10px;
}
.profileContainer {
  padding: 0 20px;
}
.four-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;
}
.swiper {
  z-index: 0 !important;
}

.site-onboard {
  position: relative;
  /* display: grid;
  grid-template-columns: 245px 1fr; */
  min-height: var(--content-height);
  width: 100%;
}
@media (max-width: 640px) {
  .site-onboard {
    grid-template-columns: auto;
    /* padding-bottom: 50vh; */
  }
}
.onboarding-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  position: relative;
  padding: 0 20px;
  margin-bottom: 60px;
}
.onboarding-cta {
  position: relative;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 40px;
  max-width: 440px;
}
.onboarding-setup {
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.onboarding-setup-cta {
  position: relative;
  bottom: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  /* width: 50%; */
  margin-right: auto;
  text-align: center;
  max-width: 440px;
}
.onboarding-status {
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 40px;
}
.onboarding-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* height: 100%; */
  justify-content: flex-start;
}
.onboard-side-menu {
  position: sticky !important;
  top: 0;
  /* height: var(--content-height); */
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid var(--gray-darker); */
  /* box-shadow: var(--border-shadow); */
  background-color: var(--black);
}
.onboard-side-menu-background{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(20px);
}
.side-menu-list{
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  
  padding-right: 20px;
}
@media (max-width: 640px) {
  .onboard-side-menu {
    display: none;
  }
}
.onboard-side-menu a {
  padding: 20px 10px 5px 0;
  text-decoration: none;
  font-size: 26px;
  color: var(--white);
  text-align: right;
}
.onboard-space-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto;
  column-gap: 20px;
}
.onborading-amenities-list{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 5px;
}
.active-sidemenu-item {
  color: var(--color-primary) !important;
  /* border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--white) ; */
}
.operation-hours{
  background-color: var(--gray-darker);
  padding: 10px;
  width: 100%;
  max-width: 360px;
  margin: auto;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--radius);
}
.operation-day-row {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;
}
.operation-day-switch{
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 640px) {
  .operation-day-row {
    grid-template-columns: auto !important;
    grid-template-rows: auto auto;
  }

  /* To make the second row full-width */
  .operation-day-row > *:nth-child(3) {
    grid-column: span 2;
  }
}

.render-open-days {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
}
.render-open-days > *:nth-child(n + 3) {
  grid-column: 2 / span 1;
}

.render-open-days h4 {
  margin: unset;
}
.rentYourSpace {
  font-weight: bold;
  color: white;
  border: 2px solid white;
  padding: 5px;
  border-radius: 10px;
}
.intro-details{
  text-align: left;
}
.intro-details ul{
  color: white;
  margin-bottom: 40px;
}
@media screen and (max-width: 640px) {
  .mobile-form{
    padding-bottom: 50vh;
  }
}
.calendar-contain{
  height: "100%";
  width: "100%";
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 20px; */
  justify-content: "flex-start",
}
@media screen and (max-width: 640px) {
  .calendar-contain{
    grid-template-columns: auto;
  }
}
.geoCoder{
  display: flex;
  justify-content: center;
}
.rate-container{
    display: flex;
    align-items: center;
    max-width: 316px;
    width: 100%;
    
    justify-content: space-between;
}
.rate-input{    
  display: grid;
  grid-template-columns: auto auto auto;
}
.rate-input span{
  display: block;
  height: 100%;
  color: var(--gray-light);
} 
.rate-input span:nth-child(1){
  font-size: 40px;
}
.rate-input span:nth-child(3){
  display: flex;
  align-items: flex-end;
  font-size: 24px;
  color: var(--gray-light);
}
.rate-num-input{
  font-size: 50px;
  color: var(--white); 
}


.dark-mode-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  padding: 5px;
  border-radius: 30px;
  background-color: var(--gray-light);
  position: relative;
  transition: background-color 0.3s ease;
}

.dark-mode-switch .icon {
  font-size: 20px;
  margin-right: 10px;
  color: var(--gray-dark);
  transition: color 0.3s ease;
}

.switch {
  width: 50px;
  height: 24px;
  background-color: var(--gray-light);
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 3px;
  transition: background-color 0.3s ease;
}

.switch.dark {
  background-color: var(--gray-darker);
}

.toggle {
  width: 18px;
  height: 18px;
  background-color: var(--white);
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.switch.dark .toggle {
  transform: translateX(26px);
}
.mui-menu-item{
  width: 100%;
  justify-content: flex-start;
  text-align: left;
}
#chat-widget-container {
  position: fixed;
  bottom: 120px; /* 100px above footer + 20px gap */
  right: 20px; /* Adjust to your desired distance from the right side */
  z-index: 9999; /* Ensure the widget is above other elements */
}

.gymtop{
display: grid;
grid-template-columns: 3fr 2fr;
grid-gap: 10px;
}
@media screen and (max-width: 880px) {
  .gymtop {
    grid-template-columns: auto;
  }
}
.options-form-container{
  position: "relative"
}
.options-form{
  position: sticky;
  top: 20px;
  min-width: 360px;
  background: var(--options-card);
  border-radius: 10px;
  padding-top: 10px;
}
.deskoptionsForm{
  position: sticky;
  top: 20px;
  min-width: 360px;
  height: 100vh;
  padding-bottom: 30%;
}
.mobileoptionsForm{
  display: none;
}
@media screen and (max-width: 880px) {
  .options-form {
    position: relative;
    min-width: 100%;
    margin-top: 20px;
  }
  .mobileoptionsForm{
    display: block;
    /* padding-bottom: 50px; */
  }
  .deskoptionsForm{
    display: none;
  }
}
.additional-info-container {
  grid-column: 1;
  grid-gap: 20px;
  display: grid;
}
.details-cta{
  text-align: left;
}
.details-cta-btn-container{
  width: 100%;
  margin-right: auto;
}
.details-cta-list li {
  padding: 10px 0;
}
